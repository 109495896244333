<template>
  <v-dialog
    v-model="model"
    :fullscreen="$vuetify.breakpoint.xs"
    width="900"
    persistent
  >
    <v-card>
      <v-card-title class="grey">
        <span>{{ title }}</span> <v-spacer />
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row dense no-gutters>
          <v-col cols="12" sm="3">
            <v-select
              v-model="query.classroom"
              :items="listClassroom"
              :loading="loadingClassroom"
              item-value="id"
              item-text="name"
              dense
              hide-details
              label="Kelas"
              class="mr-2 ma-1"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="query.subject"
              :items="listSubjects"
              :loading="loadingSubjects"
              item-text="name"
              item-value="id"
              dense
              hide-details
              label="Mata Pelajaran"
              class="mr-2 ma-1"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="5">
            <v-text-field
              v-model="query.search"
              label="Pencarian"
              outlined
              dense
              hide-details
              append-icon="mdi-magnify"
              class="mr-2 ma-1"
            />
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn class="indigo darken-3 ma-1" depressed dark @click="getData"
              >Cari</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-card flat class="pa-4">
              <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :options.sync="options"
                :server-items-length="total"
                class="elevation-0"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    class="primary"
                    depressed
                    dark
                    @click="setAction(item)"
                  >
                    {{ actionLabel || "Mulai" }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { classroom, subjects } from "@/api/master";
import { getElearning } from "@/api/elearning";
import { studentEtestList } from "@/api/etest";

export default {
  props: {
    model: Boolean,
    title: String,
    actionColumn: Boolean,
    actionLabel: String,
    type: String,
  },
  data() {
    return {
      dataClass: null,
      loadingSubjects: false,
      loadingClassroom: false,
      listSubjects: [],
      listClassroom: [],
      items: [],
      loading: false,
      total: 0,
      options: {},
      query: {
        search: "",
        classroom: "",
        subject: "",
        limit: 5,
        page: 1,
      },
      headers: [
        { text: "Judul", value: "title" },
        { text: "Mata Pelajaran", value: "subject" },
        { text: "Aksi", value: "action", align: "center", width: 150 },
      ],
    };
  },
  watch: {
    async model(value) {
      if (value) {
        await this.getClassroom();
        await this.getSubjects();
        await this.getData();
        this.query.classroom = this.getUser.g_user.student.classroom.id;

        if (
          this.actionColumn &&
          !this.headers.some((e) => e.value === "action")
        ) {
          this.headers.push({ text: "Aksi", value: "action", align: "center" });
        }
        if (this.headers.length > 3 && !this.actionColumn) {
          this.headers.pop();
        }
      }
    },
    options: {
      handler(param) {
        this.query.page = param.page;
        this.query.limit = param.itemsPerPage;

        this.getData();
      },
      deep: true,
    },
  },
  computed: {
    getUser() {
      return this.$store.getters;
    },
  },
  methods: {
    setAction(item) {
      switch (this.type) {
        case "elearning":
          this.$router.push({
            name: "ElearningDetail",
            query: { id: item.id },
          });
          break;
        case "etest":
          this.$router.push({
            name: "DoEtest",
            query: { id: item.id },
          });
          break;

        default:
          break;
      }
    },
    close() {
      this.$emit("close");
    },
    getData() {
      this.loading = true;
      switch (this.type) {
        case "elearning":
          getElearning(this.query)
            .then((res) => {
              if (res.data.code) {
                this.items = res.data.data.data;
                this.total = res.data.data.total;
              }
            })
            .finally(() => (this.loading = false));
          break;
        case "etest":
          studentEtestList(this.query)
            .then((res) => {
              if (res.data.code) {
                this.items = res.data.data.data;
                this.total = res.data.data.total;
              }
            })
            .finally(() => (this.loading = false));
          break;

        default:
          break;
      }
    },
    getSubjects() {
      this.loadingSubjects = true;
      subjects()
        .then((res) => {
          if (res.data.code) {
            this.listSubjects = res.data.data;
          }
        })
        .finally(() => (this.loadingSubjects = false));
    },
    getClassroom() {
      this.loadingClassroom = true;
      classroom()
        .then((res) => {
          if (res.data.code) {
            this.listClassroom = res.data.data;
          }
        })
        .finally(() => (this.loadingClassroom = false));
    },
  },
};
</script>
